export const reportPagePv = (path: string) => {
  if (path === '/') {
    path = '/home';
  }
  const headers = {
    Accept: 'application/json',
    'X-Kredit-Req-APPID': '85e80adc3795147182c681ce7e69cbb9',
    'Content-Type': 'application/json; charset=utf-8',
    'X-Kredit-Start-Timestamp': `${Date.now()}`,
    'X-Kredit-Req-Source': '1',
  };

  const data = {
    header: {
      region: 'id',
      biz_type: 'commerce_finance',
    },
    message: {
      reportType: 'normal',
      eventList: [
        {
          type: 'custom',
          timestamp: +new Date(),
          data: {
            messgae: 'commerce_finance_pv_report',
            extra: {
              pathName: path,
            },
            metricConfigs: [
              {
                type: 'counter',
                metricName: 'pv_count',
                desc: 'page-pv-count',
                labels: ['pathName'],
                value: 1,
              },
            ],
          },
        },
      ],
      cmd: 'report',
      pageHistory: null,
    },

    biz_common: {
      biz_country: 'id',
      biz_type: 3,
    },
  };
  fetch('/credit/insight/fe_chat', {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  }).then((rep) => {
    console.log('reportSuccess', rep);
  });
};
