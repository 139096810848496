import product_1 from '../images/product_1.png';

export interface IFaq {
  question: string;
  answer: Array<string>;
  list?: Array<string>;
  id: string;
}

export interface IProduct {
  image: string;
  productName: string;
  description: Array<string>;
}

export const faq: Array<IFaq> = [
  {
    question: 'APA ITU SPAYLATER?',
    answer: [
      'SPayLater merupakan produk hasil kerjasama dari PT Commerce Finance (“Perusahaan”) dan PT Shopee International Indonesia (“Shopee”) yang menyediakan layanan pinjaman finansial bagi Pengguna platform e-commerce Shopee sebagai metode pembayaran untuk bertransaksi membeli barang dan membayarnya di kemudian hari saat tanggal jatuh tempo. Jenis cicilan SPayLater yang disediakan yaitu cicilan 1 kali, 3 kali, 6 kali, 12 kali, 18 kali, dan 24 kali.',
    ],
    id: '1',
  },
  {
    question: 'APA SYARAT & KETENTUAN MENJADI PENGGUNA SPAYLATER?',
    answer: [
      '1. Anda telah memenuhi kriteria sebagai pengguna terpilih untuk memiliki fitur SPayLater di platform e-commerce Shopee.',
      '2. Anda adalah WNI berusia min. 17 tahun dan/ atau memiliki KTP untuk mengaktifkan SPayLater.',
    ],
    id: '2',
  },
  {
    question: 'BAGAIMANA CARA MENDAFTAR SEBAGAI PENGGUNA SPAYLATER?',
    answer: [
      '1. Anda terpilih untuk memiliki fitur SPayLater di platform e-commerce Shopee.',
      '2. Anda adalah WNI berusia min. 17 tahun dan/ atau memiliki KTP untuk mengaktifkan SPayLater.',
      "3. SPayLater dapat diaktifkan di aplikasi Shopee dengan klik 'Saya' > 'SPayLater' > 'Aktifkan Sekarang' > Masukkan kode OTP > Unggah foto & foto KTP > Masukkan informasi tambahan > Melakukan verifikasi wajah.",
      '4. Jika berhasil melalui prosedur diatas, pengguna dapat menggunakan SPayLater di platform e-commerce Shopee.',
    ],
    id: '3',
  },
  {
    question: 'BAGAIMANA SYARAT DAN KETENTUAN MENGGUNAKAN SPAYLATER?',
    answer: [
      '1. Anda telah berhasil aktifasi akun SPayLater.',
      '2. Anda dapat menggunakan SPayLater sebagai metode pembayaran di platform e-commerce Shopee sebanyak mungkin sesuai dengan limit yang dimiliki, kecuali untuk kategori voucher.',
      '3. Anda tidak dapat menggunakan SPayLater jika memiliki keterlambatan pembayaran tagihan SPayLater.',
    ],
    id: '4',
  },

  {
    question: 'BERAPA SUKU BUNGA SPAYLATER?',
    answer: [
      'Transaksi menggunakan SPayLater dikenakan suku bunga sekecil-kecilnya 2,95% yang diselesaikan dalam waktu 1 bulan, 3 bulan, 6 bulan, 12 bulan, 18 bulan, dan 24 bulan. SPayLater menetapkan biaya penanganan sebesar 1% per transaksi.',
    ],
    id: '5',
  },
  {
    question: 'BAGAIMANA CARA MENGGUNAKAN SPAYLATER?',
    answer: [
      '1. Anda dapat memilih produk yang ingin Anda beli melalui platform e-commerce Shopee.',
      "2. Pilih 'SPayLater' sebagai metode pembayaran sesuai syarat dan ketentuan penggunaan.",
      '3. Masukkan PIN ShopeePay atau verifikasi melalui OTP sebagai konfirmasi untuk melanjutkan transaksi menggunakan SPayLater.',
      '4. Pembayaran akan terkonfimrasi secara otomatis dan Penjual Shopee akan mendapatkan notifikasi untuk mengirimkan pesanan Anda.',
    ],
    id: '6',
  },
  {
    question: 'BAGAIMANA CARA MEMBAYAR TAGIHAN SPAYLATER?',
    answer: [
      '1. Anda dapat melihat rincian tagihan Anda saat tanggal tagihan Anda muncul.',
      '2. Pembayaran tagihan dapat dilakukan melalui metode pembayaran yang telah ditentukan yaitu melalui ShopeePay, Virtual Account, atau Indomaret. ',
      '3. Keterlambatan pembayaran tagihan akan dikenakan denda 5% dari total tagihan, tercatat di SLIK (Sistem Layanan Informasi Keuangan) OJK, pembekuan akun Shopee, pembatasan voucher Shopee, dan penagihan lapangan.',
    ],
    id: '7',
  },
  {
    question: 'KEBIJAKAN KEAMANAN INFORMASI',
    answer: [
      `Manajemen dan seluruh karyawan PT Commerce Finance bertekad untuk menjamin kelangsungan layanan TI dan meminimalisasi kerugian atas layanan TI dengan melindungi informasi dari ancaman terhadap kerahasiaan (confidentiality), keutuhan (integrity) dan ketersediaannya (availability).

Untuk mencapai sasaran tersebut, PT Commerce Finance melaksanakan penerapan SMKI dengan cara:`,
    ],
    list: [
      'Menetapkan sasaran keamanan informasi',
      'Mendorong kegiatan pengamanan informasi dan pencegahan insiden keamanan informasi dengan melaksanakan Sistem Manajemen Keamanan Informasi (SMKI) ISO/IEC 27001.',
      'Mengelola ketersediaan dari sumber daya yang dibutuhkan dalam rangka implementasi SMKI.',
      'Menaati hukum dan peraturan perundang-undangan yang berkaitan dengan keamanan informasi.',
      'Meningkatkan kesadaran seluruh karyawan akan pentingnya keamanan informasi.',
      'Melakukan asesmen risiko keamanan informasi.',
      'Memastikan koordinasi implementasi kontrol keamanan informasi telah dilakukan secara berkala.',
      'Melaksanakan peningkatan yang berkesinambungan dalam penerapan SMKI.',
    ],
    id: '8',
  },
];

export const products: Array<IProduct> = [
  {
    image: product_1,
    productName: 'Pembiayaan SPayLater',
    description: [
      'SPayLater merupakan produk hasil kerjasama dari PT Commerce Finance (“Perusahaan”) dan PT Shopee International Indonesia (“Shopee”). SPayLater adalah sebuah layanan pinjaman finansial bagi Pengguna yang juga merupakan metode pembayaran dalam platform e-commerce Shopee yang memungkinkan Pengguna Shopee bertransaksi untuk membeli barang dan membayarnya di kemudian hari saat tanggal jatuh tempo. Produk SPayLater memiliki tenor cicilan yang terdiri dari: 1X, 3X, 6X, 12X, 18X, dan 24X.',
      'SPayLater dikenakan suku bunga 2.95% per bulan yang diselesaikan dalam waktu 1 bulan, 3 bulan, 6 bulan, 12 bulan, 18 bulan, dan 24 bulan.',
      'SPayLater menetapkan biaya penanganan sebesar 1% per transaksi.',
      'Apabila terjadi keterlambatan pembayaran, pembeli akan didendakan denda sebesar 5%',
    ],
  },
];
