import React from 'react';
import { Grid, Image } from 'semantic-ui-react';
import bikLogo from '../images/bik-foot-logo.png';
import gencarkanLogo from '../images/gencarkan-foot-logo.png';
import appiLogo from '../images/appiLogo.png';
import logo from '../images/company-logo-removebg.png';
import phone from '../images/phone.png';
import email from '../images/email.png';
import inIcon from '../images/in-icon.png';
import { Link } from 'react-router-dom';
import '../styles/Footer.scss';

function Footer() {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <div className="top-line"></div>
      <div className="footer">
        <Grid stackable>
          <Grid.Row columns={4}>
            {/* 第一列 */}
            <Grid.Column textAlign="left">
              <div className="footer__title">
                <img
                  src={logo}
                  alt="Commerce Finance"
                  className="finance-logo"
                />
              </div>
              <div className="footer__location">
                <div>Sopo Del Tower Lt 32,</div>
                <div>Jalan Mega Kuningan Barat III Lot 10.1-6, RT.3/RW.3,</div>
                <div>Kuningan Tim., Kecamatan Setiabudi,</div>
                <div>Daerah Khusus Ibukota Jakarta 12950</div>
              </div>
            </Grid.Column>
            {/* 第二列 */}
            <Grid.Column textAlign="left">
              <div className="footer__title">PT Commerce Finance</div>
              <div className="footer__location">
                <Link onClick={handleScrollToTop} to="/faq">
                  <div className="footer__subcontact">
                    <div className="text-with-bottom">Pusat Bantuan </div>
                  </div>
                </Link>
                <Link onClick={handleScrollToTop} to="/about-us">
                  <div className="footer__subcontact">
                    <div className="text-with-bottom">Tentang Kami </div>
                  </div>
                </Link>
                <a
                  href="/pdf/CF - Prosedur-Layanan-Pengaduan-Pelanggan-SPL.docx.pdf"
                  download
                >
                  <div className="footer__subcontact">
                    <div className="text-with-bottom">
                      Layanan Pengaduan Pelanggan
                    </div>
                  </div>
                </a>
              </div>
            </Grid.Column>
            {/* 第三列 */}
            <Grid.Column textAlign="left">
              <div className="footer__title">Informasi & Panduan</div>
              <div className="footer__location">
                <a
                  href="/pdf/CF  - Terms & Conditions.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="footer__subcontact">
                    <div className="text-with-bottom">Syarat & Ketentuan</div>
                  </div>
                </a>

                <a href="/pdf/CF - RIPLAY.pdf" target="_blank">
                  <div className="footer__subcontact">
                    <div className="text-with-bottom">RIPLAY</div>
                  </div>
                </a>
              </div>
            </Grid.Column>
            {/* 第四列 */}
            <Grid.Column textAlign="left">
              <div className="footer__title">Hubungi Kami</div>
              <table className="table-content">
                <tbody>
                  <tr>
                    <td>
                      <div className="footer__contact">
                        <div className="footer__subcontact">
                          <img src={phone} alt="phone" className="icon" />
                          <div>(021) 8060 4253</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="footer__contact">
                        <div className="footer__subcontact">
                          <img src={email} alt="phone" className="icon" />
                          <a href="mailto:customerservice@cmf.co.id">
                            customerservice@cmf.co.id
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="footer__contact">
                        <div className="footer__subcontact">
                          <img src={inIcon} alt="phone" className="icon" />
                          <a
                            href="https://www.linkedin.com/company/pt-commerce-finance/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            LinkedIn
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div className="footer__watcher">
          <div className="footer__logo_group">
            <Image src={bikLogo} className="img-large" />
            <Image src={gencarkanLogo} className="img-medium" />
            <Image src={appiLogo} className="img-medium" />
          </div>
          <div className="footer_desc">
            <div>
              PT Commerce Finance Berizin dan Diawasi oleh Otoritas Jasa
              Keuangan
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
